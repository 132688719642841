<!--
 * @Description:底部我的-关于我们 aboutOur
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-27 15:19:07
 * @LastEditors: linf
-->
<!--  -->
<template>
  <div class='main'>
    <van-nav-bar title="关于我们"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="top">
      <van-image width="93"
                 height="93"
                 class="img"
                 :src="require('@/assets/mySelf/yytLogo.png')" />
      <van-row style="font-size: 17px;font-weight: 500;color: #333333;text-align:center;">渝悦停</van-row>
      <van-row style="font-size: 13px;font-weight: 500;color: #999999;text-align:center;margin-top:3px">当前版本V1.0</van-row>
      <van-row style="font-size: 13px;font-weight: 500;color: #999999;text-align:center;margin-top:3px">更新时间：2022.02.20</van-row>
    </van-row>
    <van-row class="middle"
             style="margin-top:12px">
      <van-cell is-link
                @click="showPopup('userAgreement')">用户协议</van-cell>
      <van-cell is-link
                @click="showPopup('privacyPolicy')">隐私政策</van-cell>
    </van-row>
    <van-row class="middle"
             style="margin-top:12px">
      <van-cell title="客服电话">
        <span style="color:#19A9FC;font-size: 15px;font-weight: 400;">
          15895831580
        </span>
      </van-cell>
      <van-cell title="工作日">
        <span style="color:#666666;font-size: 15px;font-weight: 400;">
          08:30-17:30
        </span>
      </van-cell>
    </van-row>
    <van-row class="middle"
             style="margin-top:12px">
      <van-cell title="公司官网">
        <span>
          <a :href=href
             style="color:#19A9FC;font-size: 15px;font-weight: 450px;">http://www.ccfourth.com</a>
        </span>
      </van-cell>
    </van-row>
    <van-row class="comp">中交四公局(重庆)城市建设发展有限公司 版权所有
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      // order: ''
      href: 'http://www.ccfourth.com/'
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 跳转
    showPopup (val) {
      if (val === 'privacyPolicy') {
        this.$router.push('/privacyPolicy')
      } else if (val === 'userAgreement') {
        this.$router.push('/userAgreement')
      }
    },

    // wxPay () {
    //   const info = {
    //     description: '渝北支付测试',
    //     orderId: this.order,
    //     amount: '1'
    //   }
    //   this.$choosePayStyle.testAppPay(info).then(res => {
    //     this.prepayid = res.resultEntity.prepayid
    //     this.nonceStr = res.resultEntity.nonceStr
    //     this.timeStamp = res.resultEntity.timeStamp
    //     this.sign = res.resultEntity.sign
    //     window.android.requestWxPay(res.resultEntity.prepayid, res.resultEntity.nonceStr, res.resultEntity.timeStamp, res.resultEntity.sign)
    // prepayid: "wx270959520109396bdaac279a4569170000",
    // nonceStr: "1619488792504"
    // timeStamp: "1619488792",
    // sign: "GImI/ZGS2y62/X6xFYeUrDAzXRb2QpiRoDhqt59gGnnfO8FYuS7AyIn8Sw5oT9dQpePmKeGztGK/u4qrCOLb18IsPS03wBROiZm6TiZ43s61hKxDtNyK5feMdc+iJ8B1Xe//cT/QkjVkwv0OWCSnrxBBzKs2WGG8yMSOKAoepSOIEXW7uhSbcj6gDDTJ4zTwZ907A/1Y+++XkA2lWYgZ3eYTvS9DtTKMgCVuAPG41oWT7HcThrPGZUAHB6fKAjmgZt/JjNqgoh2CW4hWmLxMk/RTpf6Yp4DMC5OM8BoHp9EahYUcPTAihnuILMiZ6OqA8rvbFYYG5NtOC74KE7azYg==",
    // package: "Sign=WXPay",
    // appId: "wx0bbb4373d8ba4668",
    // partnerid: "1605498492",
    //   })
    // },
    // zfbPay () {
    //   const info = {
    //     description: '渝北支付测试',
    //     orderId: this.order,
    //     amount: '1'
    //   }
    //   this.$choosePayStyle.zfbAliPay(info).then(res => {
    //     console.log('alires', res)
    //     window.android.requestAliPay(res.resultEntity)
    //   }).catch(err => {
    //     console.log('alierr', err)
    //   })
    // },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.top {
  height: 200px;
  background-color: #ffffff;
}
.img {
  margin: 20px 37.73% 8px 37.47%;
}
.middle {
  margin-top: 12px;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
}
.comp {
  margin-top: 80px;
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
</style>
